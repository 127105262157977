.mainCards {
  border-radius: 12px;
  background: #fff;

  /* Shadow/sm */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
}
.mainCards1 {
  border-radius: 12px;
  background: #fff;
  cursor: pointer;
  /* Shadow/sm */
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    transition: all 0.3s ease-in-out;
}

.mainCards1:hover {
 transform: scale(1.03);
}

.pie-cell{
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
/* .pie-cell:hover{
  transform: scale(1.01);
} */